import styled from 'styled-components';

const Text = styled.input`
    background-color: rgba(217, 217, 217, 0.21);
    border-radius: 18px;
    border: 2px solid lightgray;
    padding: 30px;
    min-width: 200px;
    width: 40%;
    
    font-family: Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    color: #B8B8B8;
    
    @media screen and (max-width: 768px) {
        font-size: 20px;
        width: 100%;
    }


`;

export {Text};
