import React, {InputHTMLAttributes} from 'react';
import {Text} from "./styles";

const TextInput = (props: Props) => {
    return (
        <Text {...props} />
    )
}

interface Props extends InputHTMLAttributes<HTMLInputElement> {

}

export default TextInput;
