import React, {useRef} from 'react';
import {MainScreen, GrayLine} from "./styles";
import Header from "../Header";
import WhyMegafair from "../WhyMegafair";
import PlayersLove from "../PlayersLove";
import AboutUs from "../AboutUs";
import AreYouIn from "../AreYouIn";

const Main = () => {
    const ref = useRef(null);

    const handleClick = () => {
        console.log('clicking')
        // @ts-ignore
        ref.current?.scrollIntoView({behavior: 'smooth'});
    };

    return (
        <MainScreen>
            <Header handleClick={handleClick}/>
            <GrayLine/>
            <WhyMegafair/>
            <PlayersLove handleClick={handleClick}/>
            <AboutUs handleClick={handleClick}/>
            <div ref={ref} style={{width: '100%', display: "flex", justifyContent: 'center', alignItems: 'center'}}>
                <AreYouIn />
            </div>
        </MainScreen>

    )
}

export default Main;
