import React from 'react';
import {Regular, Medium, Colored, Blue, ColoredBackground, Border, ColoredLight, RegularWhite, ColoredPurple} from "./styles";

const Text = (props: Props) => {
    const {text ,type} = props;
    if (type === 'medium') return <Medium>{text}</Medium>;
    if (type === 'colored') return <Colored>{text}</Colored>
    if (type === 'coloredLight') return <ColoredLight>{text}</ColoredLight>
    if (type === 'blue') return <Blue>{text}</Blue>
    if (type === 'coloredBackground') return <ColoredBackground>{text}</ColoredBackground>
    if (type === 'border') return <Border>{text}</Border>
    if (type === 'regularWhite') return <RegularWhite>{text}</RegularWhite>
    if (type === 'coloredPurple') return <ColoredPurple>{text}</ColoredPurple>
    return <Regular>{text}</Regular>
}

interface Props {
    text: string;
    type: 'medium' | 'colored' | 'regular' | 'blue' | 'coloredBackground' | 'border' | 'coloredLight' | 'regularWhite' | 'coloredPurple';
}

export default Text;
