import React, {useState} from 'react';
import {InputRow, MainBox, SpinnerBox} from './styles';
import Text from "../../components/Text";
import TextInput from "../../components/TextInput";
import TextAreaInput from "../../components/TextAreaInput";
import JoinUsButton from "../../components/JoinUsButton";
import {RotatingLines} from "react-loader-spinner";

const AreYouIn = () => {

    const [loading, setLoading] = useState<boolean>(false);
    const [showSuccess, setShowSuccess] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [message, setMessage] = useState<string>('');

    const handleSubmit = () => {

        if (name.length === 0) {
            alert('Please check the name field, should not be empty.');
            return;
        }
        if (validateEmail(email) === null) {
            alert('Please check the email field, should be valid email.');
            return;
        }
        if (message.length === 0) {
            alert('Please check the message field, should not be empty.');
            return;
        }

        setLoading(true);
        fetch(`${process.env.REACT_APP_PUBLIC_API}/send_email`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({name, email, message})
            })
            .then()
            .then(() => {
                setName('');
                setEmail('');
                setMessage('');
                setShowSuccess(true);
                setTimeout(() => setShowSuccess(false), 5000);
            })
            .catch((error) => {
                console.error(error);
                alert('Failed to send email, please try again');
            })
            .finally(() => setLoading(false));
    }

    return (
        <MainBox>
            <SpinnerBox style={{display: (loading || showSuccess) ? 'flex' : 'none'}}>
                <RotatingLines
                    strokeColor="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="96"
                    visible={loading || showSuccess}
                />
                {showSuccess && <Text text='Your Message has been successfully sent. We will contact you soon.' type='regular' />}
            </SpinnerBox>
            <Text text='MEGAFAIR IS THE FUTURE OF SKILL-GAMES' type='medium'/>
            <Text text='ARE YOU IN?' type='colored'/>
            <InputRow>
                <TextInput
                    type='text'
                    maxLength={50}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder='Name'
                />
                <TextInput
                    type='email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder='Email'
                />
            </InputRow>
            <InputRow>
                <TextAreaInput
                    maxLength={1000}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder='Message'
                />
            </InputRow>

            <JoinUsButton onClick={handleSubmit}/>
        </MainBox>
    )
}


const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};


export default AreYouIn;
