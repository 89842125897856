import React from 'react';
import {MainBox, Image, TextBox, ImageMobile} from './styles';
import about from '../../assets/aboutUs.webp';
import Text from "../../components/Text";
import AnimatedButton from "../../components/AnimatedButton";

const AboutUs = (props: Props) => {
    const {handleClick} = props;
    return (
        <MainBox>
            <TextBox>
                <Text text='ABOUT US' type='medium' />
                <ImageMobile src={about} alt='About' />
                <Text text='AN INDUSTRY FIRST GAME PROVIDER FOR SKILL CASH GAMES' type='coloredLight' />
                <Text text="Founded by online casino and casual gaming industry veterans with a focus on innovative technology and data oriented products. MegaFair provides gaming platforms and operators with the most trending category in the industry, allowing them to easily offer new games and experiences, and attract new demographics looking for the freshest games on the market. We're constantly releasing new games, upgrading our AI to optimize user experience and ROI for our operators and platforms." type='regular' />
                <AnimatedButton onClick={handleClick}/>
            </TextBox>
            <Image src={about} alt='About'/>
        </MainBox>
    )
}

interface Props {
    handleClick: () => void
}

export default AboutUs;
