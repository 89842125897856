import styled from 'styled-components';

const MainScreen = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 10;
`;

const GrayLine = styled.div`
    width: 100%;
    height: 15px;
    background-color: #D9D9D9;
`;

export {MainScreen, GrayLine};
