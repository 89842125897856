import React from "react";
import arrow from '../../assets/greenArrow.svg';
import { ArrowBox } from "./styles";

const Arrow = () => {
    return (
        <ArrowBox>
            <img src={arrow} alt="" width='18px' height='18px'/>
        </ArrowBox>
    )
}

export default Arrow;
