import React from 'react';
import {BackgroundBox, MainBox, TextBox, LastRow, ButtonBox} from './styles';
import AnimatedButton from "../../components/AnimatedButton";
import Text from "../../components/Text";

const PlayersLove = (props: Props) => {
    const {handleClick} = props;
    return (
        <BackgroundBox>
            <MainBox>
                <TextBox>
                    <Text text='PLAYERS LOVE' type='blue'/>
                    <Text text='OUR SKILL GAMES' type='border'/>
                    <LastRow>
                        <Text text='YOUR RETURNS' type='coloredBackground' />
                        <Text text='.' type='border' />
                    </LastRow>
                </TextBox>
                <ButtonBox>
                    <AnimatedButton onClick={handleClick}/>
                </ButtonBox>
            </MainBox>
        </BackgroundBox>
    )
}

interface Props {
    handleClick: () => void
}

export default PlayersLove;
