import React from 'react';
import {MainBox, IconsBox, IconBox, Icon} from './styles';
import Text from "../../components/Text/Text";
import pieSvg from '../../assets/pie.svg';
import locationSvg from '../../assets/location.svg';
import rocketSvg from '../../assets/rocket.svg';

const WhyMegafair = () => {
    return (
        <MainBox>
            <Text text='WHY MEGAFAIR?' type='medium' />
            <Text text='A NEW CATEGORY IN ONLINE GAMING.' type='colored' />
            <IconsBox>
                <IconBox>
                    <Icon src={pieSvg} alt='pie'/>
                    <Text text='Instant new unique categories for players to enjoy.' type='regular'/>
                </IconBox>
                <IconBox>
                    <Icon src={locationSvg} alt='loc'/>
                    <Text text='Full compliance with major games including USA, EU, UK and Australia.' type='regular'/>
                </IconBox>
                <IconBox>
                    <Icon src={rocketSvg} alt='rocket'/>
                    <Text text='Increase in player conversion retention, and ROI.' type='regular'/>
                </IconBox>
            </IconsBox>
        </MainBox>
    )
}

export default WhyMegafair;
