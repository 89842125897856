import styled from 'styled-components';

const ButtonBox = styled.div`
    display: flex;
    flex-direction: row;
    width: 50%;
    height: 90px;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(182, 230, 255, 0.27), rgba(222, 95, 139, 0.27), rgba(0, 0, 0, 0));
    @media screen and (max-width: 768px) {
        width: 100%;
    }
`;


const TextBox = styled.div`
    cursor: pointer;
    font-family: GilroyBold;
    font-style: normal;
    font-size: 32px;
    text-align: center;
    vertical-align: middle;
    border: 0;
    color: white;
    width: 180px;
    height: 100%;
    border-radius: 20px;
    background: linear-gradient(90deg, #29B5FF 0%, #E85984 101.48%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
`;


export {TextBox, ButtonBox};

