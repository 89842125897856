import styled from 'styled-components';

const Regular = styled.div`
    font-family: Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    letter-spacing: 1px;
    line-height: 1.3;
    color: #909090;
    @media screen and (max-width: 768px) {
        font-size: 20px;
        letter-spacing: 4px;
    }
`;

const RegularWhite = styled.div`
    font-family: Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    letter-spacing: 1px;
    line-height: 1.3;
    color: white;
    @media screen and (max-width: 768px) {
        font-size: 20px;
        letter-spacing: 4px;
    }
`;

const Blue = styled.div`
    font-family: Gilroy;
    font-size: 23px;
    letter-spacing: 5px;
    font-weight: bold;
    color: #12c2e9;
    @media screen and (max-width: 768px) {
        font-size: 20px;
        letter-spacing: 4px;
    }
`;

const Medium = styled.div`
    font-family: Gilroy;
    font-size: 30px;
    letter-spacing: 7px;
    font-weight: bold;
    color: #909090;
    @media screen and (max-width: 768px) {
        font-size: 26px;
        letter-spacing: 6px;
    }
`;

const Colored = styled.div`
    font-family: GilroyBold;
    font-size: 60px;
    font-weight: 500;
    background: linear-gradient(270.06deg, #E75883 7.65%, #28B4FF 93.54%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @media screen and (max-width: 768px) {
        font-size: 50px;
    }
`;

const ColoredPurple = styled.div`
    font-family: GilroyBold;
    font-size: 35px;
    font-weight: 800;
    background: linear-gradient(92deg, #FDE4FF 2.97%, #FB3DFF 103.34%);;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @media screen and (max-width: 768px) {
        font-size: 30px;
    }
`;

const ColoredLight = styled.div`
    font-family: GilroyBold;
    font-size: 60px;
    font-weight: 500;
    background: linear-gradient(270.06deg, #05ABB0 7.65%, #28B4FF 93.54%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @media screen and (max-width: 768px) {
        font-size: 50px;
    }
`;

const ColoredBackground = styled.div`
    font-family: GilroyBold;
    font-size: 110px;
    font-weight: 500;
    background: linear-gradient(270.06deg, #E75883 7.65%, #28B4FF 93.54%);
    color: white;
    @media screen and (max-width: 768px) {
        font-size: 60px;
    }
`;


const Border = styled.div`
    font-family: GilroyBold;
    font-size: 110px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0);
    -webkit-text-stroke: 2px gray;
    @media screen and (max-width: 768px) {
        font-size: 60px;
        -webkit-text-stroke: 1px gray;
    }

`;


export {Regular, Medium, Colored, Blue, ColoredBackground, Border, ColoredLight, RegularWhite, ColoredPurple};
