import styled from 'styled-components';

const MainBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
    align-items: center;
    justify-content: center;
    padding-bottom: 100px;
    gap: 50px;
`;


const InputRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    gap: 10px;
`;


const SpinnerBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(211,211,211, 0.9);
    z-index: 1000;
`;


export {MainBox, InputRow, SpinnerBox};