import React from 'react';
import {TextBox, ButtonBox} from './styles';

const JoinUsButton = (props: Props) => {
    const {onClick} = props;
    return (
        <ButtonBox onClick={onClick}>
            <TextBox>
                <div>
                    Join Us
                </div>
            </TextBox>
        </ButtonBox>
    )
}

interface Props {
    onClick: () => void;
}

export default JoinUsButton;
