import React from 'react';
import {Main, Text1, Text2, Text3, HomeButton} from "./styles";

const NotFound = () => {
    return (
        <Main>
            <Text1>
                404
            </Text1>
            <Text2>
                OOPS! SORRY PAGE DOES NOT FOUND
            </Text2>
            <Text3>
                We are very sorry for inconvenience. It looks like you're trying to access a page that either has been deleted or never even existed.
            </Text3>
            <HomeButton onClick={() => {window.location.href='https://www.megafair.io'}}>
                Back to home
            </HomeButton>
        </Main>
    )
}

export default NotFound;
