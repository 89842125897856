import styled from 'styled-components';

const Text = styled.div`
    font-family: GilroyBold;
    font-style: normal;
    font-size: 32px;
    // text-align: center;
    color: #FFFFFF;
    width: 0px;
    white-space: nowrap;
    overflow: hidden;
    transition: width 1s;
    position: relative;
    margin-right: 40px;
    left: 40px;
    @media screen and (max-width: 768px) {
        font-size: 30px;
        margin-right: 10px;
    }

`;

const ButtonBox = styled.div`
    display: flex;
    flex-direction: row;
    width: 370px;
    height: 90px;
    // justify-content: center;
    align-items: center;
    background: linear-gradient(90deg, #03AAB3 0%, #58D92B 101.48%);
    border-radius: 15px;
    position: relative;
    cursor: pointer;
    @media screen and (max-width: 768px) {
        width: 330px;
        height: 70px;
    }

`;

const ArrowBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;    
    width: 36px;
    height: 36px;
    border-radius: 20px;
    background-color: white;
    position: relative;
`;


export {Text, ButtonBox, ArrowBox};

