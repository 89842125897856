import styled from 'styled-components';

const MainBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 90%;
    align-items: center;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
    gap: 60px;
    text-align: center;
    background-color: white;
`;

const IconsBox = styled.div`
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    // background-color: blue;
    text-align: center;
    flex-wrap: wrap;
`;

const IconBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    // background-color: lightgray;
    width: 300px;
    height: 350px
`;

const Icon = styled.img`
    width: 130px;
    height: 130px;
`;

export {MainBox, IconsBox, IconBox, Icon};