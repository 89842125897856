import styled from 'styled-components';

const MainBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    padding-top: 100px;
    padding-bottom: 100px;
    flex-wrap: wrap;
    gap: 20px;
`;

const TextBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 600px;
    gap: 40px;
`;

const Image = styled.img`
    width: 45%;
    min-width: 400px;
    height: auto;
    @media screen and (max-width: 768px) {
        display: none;
    }
`;

const ImageMobile = styled.img`
    display: none;
    height: auto;
    width: 100%;
    @media screen and (max-width: 768px) {
        display: block;
    }
`;

export {MainBox, Image, TextBox, ImageMobile};