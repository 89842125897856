import styled from 'styled-components';

const BackgroundBox = styled.div`
    background-image: url("pattern.svg");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #F5F5F5;

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 768px) {
        background-image: none;
    }
`;


const MainBox = styled.div`
    display: flex;
    flex-direction: row;
    width: 80%;
    align-items: flex-end;
    justify-content: flex-start;
    flex-wrap: wrap;
    z-index: 100;
    gap: 50px;
    padding-top: 100px;
    padding-bottom: 100px;
    @media screen and (max-width: 768px) {
        background-image: none;
    }
`;

const TextBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;

const ButtonBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;

const LastRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
`;

export {BackgroundBox, MainBox, TextBox, ButtonBox, LastRow};