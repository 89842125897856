import styled from "styled-components";

const Main = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    gap: 30px;
    overflow-y: scroll;
`;

const Text1 = styled.div`
    font-family: 'GilroyBold';
    font-style: normal;
    font-weight: 800;
    font-size: 330px;
    text-align: center;
    color: #000000;
    margin: 0;
    line-height: 300px;
    @media screen and (max-width: 768px) {
        font-size: 180px;
        line-height: 150px;
    }
`;

const Text2 = styled.div`
    font-family: 'GilroyBold';
    font-style: normal;
    font-weight: 800;
    font-size: 52px;
    text-align: center;
    width: 630px;
    background: linear-gradient(270.06deg, #05ABB0 7.65%, #28B4FF 93.54%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 64px;
    @media screen and (max-width: 768px) {
        font-size: 40px;
        width: 80%;
        line-height: 50px;
    }
`;

const Text3 = styled.div`
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    text-align: center;
    color: #909090;
    width: 1000px;
    line-height: 150%;
    padding-top: 20px;
    padding-bottom: 60px;
    @media screen and (max-width: 768px) {
        font-size: 20px;
        width: 80%;
        padding-top: 10px;
        padding-bottom: 20px;
    }
`;

const HomeButton = styled.button`
    font-family: 'GilroyBold';
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 44px;
    text-align: center;
    
    color: #FFFFFF;
    width: 400px;
    height: 100px;
    
    background: linear-gradient(90deg, #03AAB3 0%, #58D92B 101.48%);
    border-radius: 20px;
    border-width: 0;
    @media screen and (max-width: 768px) {
        font-size: 27px;
        width: 300px;
        height: 80px;
    }
`;

export {Main, Text1, Text2, Text3, HomeButton};