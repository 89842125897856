import React, {useEffect, useRef} from 'react';
import {Text, ButtonBox} from './styles';
import Arrow from "./Arrow";

const AnimatedButton = (props: Props) => {
    const {onClick} = props;
    const ref = useRef(null);

    useEffect(() => {
        if (ref !== null) {
            //@ts-ignore
            ref.current.style.width = '260px'
        }
        return () => {
            if (ref !== null) {
                //@ts-ignore
                ref.current.style.width = '0px'
            }
        }
    }, [])

    return (
        <ButtonBox onClick={onClick}>
            <Text ref={ref}>
                Partner With Us
            </Text>
            <Arrow/>
        </ButtonBox>
    )
}

interface Props {
    onClick: () => void;
}

export default AnimatedButton;
