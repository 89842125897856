import React from 'react';
import Main from "./screens/Main";
import {RouterProvider, createBrowserRouter} from "react-router-dom";
import NotFound from "./screens/NotFound/NotFound";

const App = () => {
    const router = createBrowserRouter([
      {
        path: "/",
        element: <Main />,
        errorElement: <NotFound />,
      },
    ]);
    return (
        <RouterProvider router={router}/>
    );
}

export default App;
