import styled from 'styled-components';

const Text = styled.textarea`
    background-color: rgba(217, 217, 217, 0.21);
    border-radius: 18px;
    border-width: 2px;
    border-color: lightgray;
    padding: 30px;
    width: 100%;
    height: 200px;
    resize: none;
    
    font-family: Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    color: #B8B8B8;
    
    @media screen and (max-width: 768px) {
        font-size: 20px;
    }

`;

export {Text};
