import React from 'react';
import {MainBox, TextBox, Logo, InnerTextBox} from './styles';
import logo from '../../assets/logo.webp';
import AnimatedButton from "../../components/AnimatedButton";
import Text from "../../components/Text";

const Header = (props: Props) => {
    const {handleClick} = props;
    return (
        <MainBox>
            <TextBox>
                <Logo src={logo} alt='MegaFair' />
                <InnerTextBox>
                    <Text text='Skill based games, a new category for your platform.' type='coloredPurple'/>
                    <Text text='Delivering the best skill games seamlessly to your platform to bring on instant increase in engagement, retention, and an unbeatable ROI.' type='regularWhite'/>
                </InnerTextBox>
                <AnimatedButton onClick={handleClick}/>
            </TextBox>
        </MainBox>
    )
}

interface Props {
    handleClick: () => void
}

export default Header;
