import React, {TextareaHTMLAttributes} from 'react';
import {Text} from "./styles";

const TextAreaInput = (props: Props) => {
    return (
        <Text {...props} />
    )
}

interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {

}

export default TextAreaInput;
