import styled from 'styled-components';

const MainBox = styled.div`
    background-image: url("banner.webp");
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
      
    width: 100%;
    height: 900px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 768px) {
        background-attachment: scroll;
        background-size: auto 100%;
        background-position: 0px 00px;
    }

`;

// const Image = styled.img`
//     width: 100%;
//     display: none;
//     @media screen and (max-width: 768px) {
//         // display: block;
//     }
// `;

const TextBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 400px;
    height: 100%;
    padding: 50px;
    margin-left: 150px;
    background: linear-gradient(180deg, rgba(156, 106, 255, 0.83) 2.83%, rgba(156, 106, 255, 0.1245) 96.74%);
    gap: 50px;
    @media screen and (max-width: 768px) {
        width: calc(100% - 100px);
        margin-left: 0;
    }
`

const InnerTextBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    gap: 50px;
`

const Logo = styled.img`
    width: 300px;
    height: auto;
`;

export {MainBox, TextBox, Logo, InnerTextBox};